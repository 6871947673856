@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,300;0,400;1,300;1,400&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Neucha&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=East+Sea+Dokdo&display=swap'); */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: inherit;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/*****************************/

body {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 16px;
  overflow-y: scroll; /* always show scrollbar so header doesn't flicker when loading tabs */
}

:root {
  --page-padding: 40px;
}

@media (max-width: 720px) { /* Keep in sync with JS */
  :root {
    --page-padding: 20px;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

i {
  font-style: italic;
}

b {
  font-weight: 500;
}

img {
  user-select: none;
}

.page {
  max-width: 1440px;
  margin: auto;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.icon-button {
  cursor: pointer;
}

@media (min-width: 1440px) {
  .full-width {
    padding: 0 var(--page-padding);
  }
}

.desktop-header, .mobile-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  line-height: initial;
}

.desktop-header {
  padding: var(--page-padding);
}

.desktop-header h1, .mobile-header h1 {
  font-family: "semplicitapro", 'Lato', sans-serif;
  font-weight: 700;
  font-size: 22px;
  letter-spacing: .05em;
  line-height: 16px;
  text-transform: uppercase;
}

.desktop-header nav ul {
  display: flex;
  align-items: center;
}

.desktop-header nav ul li {
  margin-left: var(--page-padding);
  font-size: 14px;
  line-height: 16px;
  letter-spacing: .05em;
  text-transform: uppercase;
}

.desktop-header nav ul li:first-child {
  margin-left: 0px;
}

.desktop-header nav ul li .active {
  border-bottom: 1px solid black;
}

.desktop-header nav ul li .active:hover {
  border-color: #ff007d;
}

.desktop-header.footer-nav {
  justify-content: center;
  margin-top: var(--page-padding);
}

a:hover {
  color: #ff007d;
  fill: #ff007d;
}

svg {
  display: block;
}

nav svg {
  width: 16px;
}

.mobile-header {
  display: none;
  box-sizing: border-box;
  align-items: center;
}

.mobile-header > * {
  padding: var(--page-padding);
}

@media (max-width: 720px) { /* Sync with JS */
  .desktop-header { display: none; }
  .mobile-header { display: flex; }
}

.mobile-nav {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: white;
  box-sizing: border-box;
  padding: 40px;
  text-align: center;
  z-index: 1;
}

.mobile-nav li {
  margin: 40px 0;
  display: flex;
  justify-content: center;
  letter-spacing: .05em;
  text-transform: uppercase;
}

.mobile-nav .x {
  position: absolute;
  top: 0;
  right: 0;
  padding: var(--page-padding);
}

.new-disclaimer {
  margin: 20px auto 40px;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  /* text-transform: uppercase; */
}

strong {
  font-weight: bold;;
}

.gallery-row {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-evenly;
  padding: 0 var(--page-padding) 20px;
  align-items: flex-start;
}

.gallery-row.hidden {
  visibility: hidden;
}

.gallery-label {
  font-size: 12px;
  letter-spacing: .05em;
  line-height: 18px;
  margin-top: 7px;
}

.gallery-item-title {
  /* text-transform: uppercase; */
}

@media (max-width: 720px) { /* Sync with JS */
  .gallery-row {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .gallery-row img {
    width: calc(100% - 2 * var(--page-padding));
    padding-top: var(--page-padding);
  }

  .gallery-row picture {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .gallery-label {
    padding: 0 var(--page-padding);
  }
}

.visdev-splash {
  position: relative;
}

.visdev-splash h1 {
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
  font-size: 50px;
  font-weight: 500;
  font-family: "semplicitapro", 'Lato', sans-serif;
}

@media (max-width: 800px) {
  .visdev-splash h1 {
    top: 25%;
    font-size: 30px;
  }
}

.visdev-splash img {
  /* height: 400px; */
  width: 100%;
  object-fit: cover;
  padding-bottom: var(--page-padding);
}

h2 {
  text-align: center;
  padding: 50px 0;
}

.visdev-lineup {
  width: 100%;
  box-sizing: border-box;
  padding: 0 var(--page-padding);
}

.color-keys {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 var(--page-padding);
  width: 100%;
  box-sizing: border-box;
}

.color-keys img {
  width: 25%;
  padding: 10px 0;
  height: initial;
}

@media (max-width: 1000px) {
  .color-keys img {
    width: 33%;
  }
}

.two-col {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.two-col div, .two-col-reverse div {
  width: 50%;
  max-width: 600px;
  padding: calc(2*var(--page-padding));
  box-sizing: border-box;
}

.two-col div:first-child {
  padding-right: var(--page-padding);
}

.two-col div:last-child {
  padding-left: var(--page-padding);
}

.two-col-reverse {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row-reverse;
}

.two-col-reverse div {
  padding-left: var(--page-padding);
}

.two-col img, .two-col-reverse img {
  width: 50%;
  object-fit: contain;
  box-sizing: border-box;
  padding: calc(2*var(--page-padding));
  padding-right: 0;
}

.two-col-reverse .width-30 {
  width: 35%;
}

@media (max-width: 950px) {
  .two-col, .two-col-reverse {
    flex-direction: column;
  }

  .two-col div, .two-col img,
  .two-col-reverse div, .two-col-reverse img,
  .two-col-reverse .width-30 {
    width: 100%;
    max-width: 100%;
    padding: var(--page-padding);
  }

  .two-col img, .two-col-reverse img {
    padding-top: 0;
  }

  .two-col .two-col-vert-divider {
    width: 200px;
    border-bottom: 1px solid #ccc;
    padding: 0;
    margin: auto;
  }
}

p {
  margin-bottom: 10px;
  line-height: 1.7em;
}

.page-footer {
  font-size: 10px;
  color: gray;
  text-align: center;
  padding: 200px 0 var(--page-padding);
}

.lightbox {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, .95);
  user-select: none;
}

.lightbox path {
  fill: white;
}

.lightbox-x {
  position: absolute;
  top: 0;
  right: 0;
  padding: var(--page-padding);
  z-index: 1;
}

.lightbox-img {
  flex-shrink: 1;
  max-width: 90vw;
  max-height: 90vh;
  min-width: 0;
  min-height: 0;
  object-fit: contain;
}

.lightbox-prev, .lightbox-next {
  align-self: stretch;
  display: flex;
  align-items: center;
  padding: var(--page-padding);
  opacity: .3;
}

.lightbox-prev:hover, .lightbox-next:hover {
  opacity: 1;
  transition: opacity .2s ease-out;
}

.hidden {
  visibility: hidden;
}

@media (max-width: 500px) {
  .lightbox {
    justify-content: center;
  }
  .lightbox-prev, .lightbox-next {
    position: absolute;
    bottom: 0;
  }
  .lightbox-prev {
    left: 0;
  }
  .lightbox-next {
    right: 0;
  }
  .lightbox-img {
    max-height: calc(100vh - 240px);
  }
}
